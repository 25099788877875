<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 测评题目 -->
        <div class="answerbox">
            <div class="answercon">
                <div class="title">专业定位综合测评</div>
                <div class="anbox">
                    <el-progress :text-inside="true" :stroke-width="strokewidth" :percentage="percentage" :show-text="false"></el-progress>
                    <div v-if="answershow">
                        <div class="number">第{{answerindex}}题，共{{QuestionData.length}}题</div>
                        <div class="topic">{{answerindex}}、{{QuestionData[answerindex-1].Question}}</div>
                        <div class="angroup">
                            <div class="anitem" @click="answerbtn(QuestionData[answerindex-1],item.AnswerValue)" v-for="(item,index) in QuestionData[answerindex-1].AnswerList" :key="index"><span class="item">{{item.AnswerItem}}</span><i class="el-icon-check"></i></div>
                        </div>
                    </div>
                    <div class="complete" v-else>
                        <i class="iconfont">&#xe607;</i>
                        <div class="tit">您已完成所有题目</div>
                        <div class="submit" @click.prevent="AllroundTestSubmit()">提交测评</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 温馨提示 -->
        <div class="anlayer" :class="layerclass" v-if="layershow">
            <div class="anmark" :class="layerboxclass">
                <div class="tt">温馨提示</div>
                <div class="xx">1、问卷选项没有“对”与“错”之分。请选择你是怎样做的，而不是选择你认为哪样更好。</div>
                <div class="xx">2、测试的目的是反映最真实的自己。请最大程度放松下来，选择更接近你平时的感受或行为的那项。</div>
                <div class="xx">3、共{{QuestionData.length}}题，约15分钟可以做完。</div>
                <div class="btn" @click.prevent="closebtn()">明白了，开始吧</div>
                <div class="close" @click.prevent="closebtn()"><i class="iconfont">&#xe620;</i></div>
            </div>
        </div>

        <!-- 加载效果 -->
        <div id="result_download_loading" style="display:none;width:100%;height:100%;position:fixed;left:0px;top:0px;z-index:888;background-color:rgba(0,0,0,0.2);">
            <div style="width:200px;height:80px;position:absolute;top:calc(50% - 40px);left:calc(50% - 100px);text-align:center;">
                <i class="el-icon-loading" style="font-size:40px;color:#fff;"></i>
                <span style="display:block;font-size:20px;color:#777;margin-top:10px;">数据提交中…</span>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import { setStore } from "@/utils/utils";
import { parseTime } from "@/utils/index";
const _ = require('lodash');
import API from '@/api/config';
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            answershow:true,
            strokewidth:16,
            percentage:0,
            answerindex:1,
            layershow:true,
            layerclass:'layershow',
            layerboxclass:'layerboxshow',
            QuestionData:[{}],
            AnswerList:[
                {
                    Dimensionality:1,
                    Result:'',
                    ScoreList:[
                        {Category: 'A',Value: 0},
                        {Category: 'C',Value: 0},
                        {Category: 'E',Value: 0},
                        {Category: 'I',Value: 0},
                        {Category: 'R',Value: 0},
                        {Category: 'S',Value: 0}
                    ]
                },
                {
                    Dimensionality:2,
                    Result:'',
                    ScoreList:[
                        {Category: '空间',Value: 0},
                        {Category: '逻辑',Value: 0},
                        {Category: '内省',Value: 0},
                        {Category: '人际',Value: 0},
                        {Category: '身体',Value: 0},
                        {Category: '音乐',Value: 0},
                        {Category: '语言',Value: 0},
                        {Category: '自然',Value: 0}
                    ]
                },
                {
                    Dimensionality:3,
                    Result:'',
                    ScoreList:[
                        {Category: 'E',Value: 0,Mark: '外向'},
                        {Category: 'F',Value: 0,Mark: '感性'},
                        {Category: 'I',Value: 0,Mark: '内向'},
                        {Category: 'J',Value: 0,Mark: '稳定'},
                        {Category: 'N',Value: 0,Mark: '直觉'},
                        {Category: 'P',Value: 0,Mark: '顺变'},
                        {Category: 'S',Value: 0,Mark: '实感'},
                        {Category: 'T',Value: 0,Mark: '理性'}
                    ]
                },
                {
                    Dimensionality:4,
                    Result:'',
                    ScoreList:[
                        {Category: '安全',Value: 0},
                        {Category: '创造',Value: 0},
                        {Category: '服务',Value: 0},
                        {Category: '管理',Value: 0},
                        {Category: '技术',Value: 0},
                        {Category: '生活',Value: 0},
                        {Category: '挑战',Value: 0},
                        {Category: '自主',Value: 0}
                    ]
                },
                {
                    Dimensionality:5,
                    Result:'',
                    ScoreList:[
                        {Category: '地理',Value: 0},
                        {Category: '化学',Value: 0},
                        {Category: '历史',Value: 0},
                        {Category: '生物',Value: 0},
                        {Category: '数学',Value: 0},
                        {Category: '物理',Value: 0},
                        {Category: '英语',Value: 0},
                        {Category: '语文',Value: 0},
                        {Category: '政治',Value: 0}
                    ]
                },
                {
                    Dimensionality:6,
                    Result:'',
                    ScoreList:[
                        {Category: '经济学',Value: 0},
                        {Category: '管理学',Value: 0},
                        {Category: '哲学',Value: 0},
                        {Category: '农学',Value: 0},
                        {Category: '法学',Value: 0},
                        {Category: '理学',Value: 0},
                        {Category: '文学',Value: 0},
                        {Category: '教育学',Value: 0},
                        {Category: '艺术学',Value: 0},
                        {Category: '工学',Value: 0},
                        {Category: '医学',Value: 0},
                        {Category: '体育',Value: 0},
                        {Category: '历史学',Value: 0}
                    ]
                }
            ],
            ResultData:{
                CreateTime:'',
                Answers:[]
            }
        }
    },
    mounted(){
        this.GetAllroundQuestionList();// 获取综合测评问答数据
    },
    methods:{

        // 选择答案
        answerbtn:_.debounce(function(item,val) {
            if(this.answerindex<this.QuestionData.length){
                this.answerindex++;
                this.percentage+=(100 / (this.QuestionData.length - 1));
            }
            else{
                this.answershow = false;
            }
            // 插入对应的维度得分
            let wd = this.AnswerList.find(f=>f.Dimensionality==item.Dimensionality);
            if(wd!=undefined&&wd!=null&&wd!=''){
                let s = wd.ScoreList.find(f=>f.Category==item.Category);
                if(s!=undefined){
                    s.Value+=parseInt(val);//插入得分
                }
            }
        }, 200, {
            'leading': true, //在延迟开始前立即调用事件
            'trailing': false, //在延时结束后不调用,保证事件只被触发一次
        }),

        // 获取综合测评问答数据
        GetAllroundQuestionList(){
            API.Career.AllroundQuestionList().then(res => {
                if(res.code==0){
                    this.QuestionData = res.data;
                }
                else{
                    this.$message.error(res.message);
                }
            })
            .catch(err => {
                this.$message.error(err);
            })
        },

        // 提交测评
        AllroundTestSubmit(){
            document.getElementById("result_download_loading").style.display = 'block';
            this.setInterestResult();// 设置兴趣维度结果
            this.setCharacterResult();// 设置性格维度结果
            this.setAbilityResult();// 设置能力维度结果
            this.setValueResult();// 设置价值观维度结果
            this.setSubjectResult();// 设置学科维度结果

            API.Career.AddAllroundTest({AnswerList: JSON.stringify(this.AnswerList)}).then(res => {
                if(res.code == 0){
                    this.ResultData.CreateTime = parseTime(new Date(),'{y}-{m}-{d}');
                    this.ResultData.Answers = JSON.stringify(this.AnswerList);
                    setStore('AllroundResultData',this.ResultData);
                    this.$router.push('/allround-result');
                }
                else{
                    this.$message.error(res.message);
                }
                document.getElementById("result_download_loading").style.display = 'none';
            })
            .catch(err => {
                this.$message.error(err);
                document.getElementById("result_download_loading").style.display = 'none';
            })
        },

        // 设置兴趣维度结果
        setInterestResult(){
            let resultStr = '';
            let Interest1 = this.AnswerList.find(f=>f.Dimensionality==1);
            
            // 获取第一个最高分数的代码
            var maxScore1 = Math.max.apply(Math, Interest1.ScoreList.map(function(o) {
                return o.Value;
            }));
            let obj1 = Interest1.ScoreList.find(f=>f.Value==maxScore1);
            resultStr+=obj1.Category;

            // 获取第二个最高分数的代码
            let Interest2 = Interest1.ScoreList.filter(f=>f.Category!=obj1.Category);
            var maxScore2 = Math.max.apply(Math, Interest2.map(function(o) {
                return o.Value;
            }));
            let obj2 = Interest2.find(f=>f.Value==maxScore2);
            resultStr+=obj2.Category;

            // 获取第三个最高分数的代码
            let Interest3 = Interest2.filter(f=>f.Category!=obj2.Category);
            var maxScore3 = Math.max.apply(Math, Interest3.map(function(o) {
                return o.Value;
            }));
            let obj3 = Interest3.find(f=>f.Value==maxScore3);
            resultStr+=obj3.Category;

            Interest1.Result = resultStr;
        },

        // 设置性格维度结果
        setCharacterResult(){
            let Character = this.AnswerList.find(f=>f.Dimensionality==3);
            let E = Character.ScoreList.find(f=>f.Category=='E');
            let I = Character.ScoreList.find(f=>f.Category=='I');
            if(E.Value>=I.Value){
                Character.Result+=E.Category;
            }
            else{
                Character.Result+=I.Category;
            }
            let S = Character.ScoreList.find(f=>f.Category=='S');
            let N = Character.ScoreList.find(f=>f.Category=='N');
            if(S.Value>=N.Value){
                Character.Result+=S.Category;
            }
            else{
                Character.Result+=N.Category;
            }
            let T = Character.ScoreList.find(f=>f.Category=='T');
            let F = Character.ScoreList.find(f=>f.Category=='F');
            if(T.Value>=F.Value){
                Character.Result+=T.Category;
            }
            else{
                Character.Result+=F.Category;
            }
            let J = Character.ScoreList.find(f=>f.Category=='J');
            let P = Character.ScoreList.find(f=>f.Category=='P');
            if(J.Value>=P.Value){
                Character.Result+=J.Category;
            }
            else{
                Character.Result+=P.Category;
            }
        },

        // 设置能力维度结果
        setAbilityResult(){
            let Ability = this.AnswerList.find(f=>f.Dimensionality==2);
            Ability.ScoreList.forEach(e => {
                if(this.GetAbilityLowScoreAdvise(e.Value)==false){
                    if(Ability.Result==""){
                        Ability.Result+=e.Category;
                    }
                    else{
                        Ability.Result+=','+e.Category;
                    }
                }
            });
        },
        // 判断能力维度低分数的建议
        GetAbilityLowScoreAdvise(num){
            if(4<=num&&num<=9){
                return true;
            }
            else{
                return false;
            }
        },

        // 设置价值观维度结果
        setValueResult(){
            
            let resultStr = '';
            let Value1 = this.AnswerList.find(f=>f.Dimensionality==4);
            
            // 获取第一个最高分数的代码
            var maxScore1 = Math.max.apply(Math, Value1.ScoreList.map(function(o) {
                return o.Value;
            }));
            let obj1 = Value1.ScoreList.find(f=>f.Value==maxScore1);
            resultStr+=obj1.Category+',';

            // 获取第二个最高分数的代码
            let Value2 = Value1.ScoreList.filter(f=>f.Category!=obj1.Category);
            var maxScore2 = Math.max.apply(Math, Value2.map(function(o) {
                return o.Value;
            }));
            let obj2 = Value2.find(f=>f.Value==maxScore2);
            resultStr+=obj2.Category+',';

            // 获取第三个最高分数的代码
            let Value3 = Value2.filter(f=>f.Category!=obj2.Category);
            var maxScore3 = Math.max.apply(Math, Value3.map(function(o) {
                return o.Value;
            }));
            let obj3 = Value3.find(f=>f.Value==maxScore3);
            resultStr+=obj3.Category;

            Value1.Result = resultStr;
        },

        // 设置学科维度结果
        setSubjectResult(){
            let Subject = this.AnswerList.find(f=>f.Dimensionality==5);
            Subject.ScoreList.forEach(e => {
                if(this.GetSubjectLowScoreAdvise(e.Value)){
                    if(Subject.Result==""){
                        Subject.Result+=e.Category;
                    }
                    else{
                        Subject.Result+=','+e.Category;
                    }
                }
            });
        },
        // 判断学科维度高低分数的建议
        GetSubjectLowScoreAdvise(num){
            if(7<=num&&num<=10){
                return true;
            }
            else{
                return false;
            }
        },

        // 关闭提示
        closebtn(){
            this.layerclass='layerhide';
            this.layerboxclass="layerboxhide";
            let st = setTimeout(()=>{
                this.layershow=false;
                clearTimeout(st);
            },300);
        }
    }
}
</script>
<style lang="less" scoped>
.answerbox{
    max-width: 1420px;
    margin: auto;
    .answercon{
        margin: 20px 20px 30px;
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 60px;
        min-height: 500px;
        user-select: none;
        .title{
            color: #555;
            font-size: 26px;
            text-align: center;
        }
        .anbox{
            margin: 0px 50px;
            .number{
                color: #666;
                font-size: 14px;
                height: 40px;
                line-height: 40px;
            }
            .topic{
                color: #555;
                font-size: 18px;
                font-weight: bold;
                margin-top: 50px;
            }
            .angroup{
                margin-top: 10px;
                .anitem{
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background-color: rgba(var(--themecolor),0.05);
                    margin-top: 15px;
                    border-radius: 3px;
                    color: rgb(var(--themecolor));
                    font-size: 16px;
                    border: 1px solid #ddd;
                    font-weight: bold;
                    letter-spacing: 1px;
                    cursor: pointer;
                    transition:all 0.2s;
                    -webkit-transition:all 0.2s;
                    .item{
                        display: inline-block;
                        text-align: center;
                        width: 100px;
                        padding-left: 12px;
                    }
                    i{
                        color: rgba(var(--themecolor),0.85);
                        display: none;
                    }
                }
                .anitem:active i{
                    display: inline-block;
                }
                .anitem:hover{
                    background-color: rgba(var(--themecolor),0.1);
                }
                .anitem:active{
                    border: 1px solid rgba(var(--themecolor),1);
                    background-color: rgba(var(--themecolor),0.1);
                }
            }
            .complete{
                margin-top: 50px;
                .iconfont{
                    text-align: center;
                    display: block;
                    font-size: 80px;
                    color: #53af53;
                }
                .tit{
                    text-align: center;
                    font-size: 22px;
                    color: #666;
                    margin-top: 10px;
                    letter-spacing: 5px;
                }
                .submit{
                    width: 180px;
                    height: 46px;
                    line-height: 46px;
                    margin: 50px auto 0px;
                    color: #fff;
                    background-color: rgba(var(--themecolor),0.85);
                    border-radius: 30px;
                    text-align: center;
                    font-size: 18px;
                    letter-spacing: 2px;
                    cursor: pointer;
                    transition: all 0.2s;
                }
                .submit:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
    }
}
.anlayer.layershow{
    animation:layershow 0.3s ease 0s 1;
    -webkit-animation:layershow 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    @keyframes layershow {
        from {opacity: 0;}
        to {opacity: 1;}
    }
}
.anlayer.layerhide{
    animation:layerhide 0.3s ease 0s 1;
    -webkit-animation:layerhide 0.3s ease 0s 1;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
    @-webkit-keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
    @keyframes layerhide {
        from {opacity: 1;}
        to {opacity: 0;}
    }
}
.anlayer{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.3);
    .anmark.layerboxshow{
        animation:layerboxshow 0.3s ease 0s 1;
        -webkit-animation:layerboxshow 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxshow {
            from {top: calc(50% - 220px);}
            to {top: calc(50% - 200px);}
        }
        @keyframes layerboxshow {
            from {top: calc(50% - 220px);}
            to {top: calc(50% - 200px);}
        }
    }
    .anmark.layerboxhide{
        animation:layerboxhide 0.3s ease 0s 1;
        -webkit-animation:layerboxhide 0.3s ease 0s 1;
        animation-fill-mode:forwards;
        -webkit-animation-fill-mode:forwards;
        @-webkit-keyframes layerboxhide {
            from {top: calc(50% - 200px);}
            to {top: calc(50% - 220px);}
        }
        @keyframes layerboxhide {
            from {top: calc(50% - 200px);}
            to {top: calc(50% - 220px);}
        }
    }
    .anmark{
        max-width: 700px;
        height: 400px;
        background-color: #fff;
        border: 1px solid #ddd;
        position: relative;
        top: calc(50% - 200px);
        margin: auto;
        border-radius: 10px;
        overflow: hidden;
        .tt{
            color: #666;
            font-size: 18px;
            letter-spacing: 2px;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .xx{
            color: #666;
            font-size: 14px;
            margin: 15px 20px;
        }
        .btn{
            width: 200px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            letter-spacing: 2px;
            background-color: rgba(var(--themecolor),0.7);
            margin: 80px auto 0px;
            border-radius: 3px;
            transition: all 0.2s;
            -webkit-transition: all 0.2s;
        }
        .btn:hover{
            cursor: pointer;
            background-color: rgba(var(--themecolor),0.8);
        }
        .close{
            position: absolute;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            top: 0px;
            right: 0px;
            z-index: 1;
            font-size: 18px;
            color: #999;
        }
        .close:hover{
            cursor: pointer;
            color: rgb(var(--themecolor));
        }
    }
}
</style>
<style>
.answerbox .answercon .anbox .el-progress{
    margin-top: 30px;
}
.answerbox .answercon .anbox .el-progress-bar__outer{
    background-color: rgb(217, 219, 221) !important;
}
.answerbox .answercon .anbox .el-progress-bar__inner{
    background-color: rgba(var(--themecolor),0.5) !important;
    background-image: linear-gradient(to right, rgba(var(--themecolor),0), rgba(var(--themecolor),1));
}
.answerbox .answercon .anbox .el-progress-bar__innerText{
    color: #f7f6f6 !important;
    font-size: 12px;
}
</style>